.Container {
    padding: 10px 30px;
    overflow: hidden;
    /* Barcha scrollni yo'qotadi */
}

.Container {
    max-width: 100vw;
    /* Sahifaning to'liq kengligida qoladi */
    overflow-x: hidden;
    /* Gorizontal scrollni yo'qotadi */
}

html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /* Gorizontal scrollni yo'qotadi */
}

.text_img {
    max-width: 100vw;
    /* Sahifaning to'liq kengligida qoladi */
    max-height: 100vh;
    /* Sahifaning to'liq balandligida qoladi */
    overflow: hidden;
    /* Scrollni yo'qotadi */
}

body {
    margin: 0;
    padding: 0;
}

.header,
.text_img,
.Avfzalliklar,
.Service,
.katta_img,
.katta,
.faq,
.malumot,
.footer {
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 330px) {
    .Container {
        width: 100%;
        padding: 10px;
    }
}

@media only screen and (max-width: 1200px) {
    .Container {
        width: 100%;
        padding: 10px;
    }
}




/* ====================== header ==================== */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.Union {
    display: flex;
    width: 40%;
}

.Union img {
    width: 3%;
    height: 18px;

}

.Union h6 {
    width: 70%;
    padding: 0px 0px 0px 8px;
    font-size: 18px;
}

.ull {
    display: flex;
    width: 28%;
}

.ull ul li {
    list-style: none;
    padding: 0px 10px;
    color: #666666;
    font-size: 16px;
}

.aa {
    text-decoration: none;
    list-style: none;
    padding: 0px 10px;
    color: #666666;
    font-size: 16px;
}

.ull ul {
    display: flex;

    justify-content: space-between;
}

.but {
    width: 9.7%;
    align-items: end;
}

.but button {

    padding: 8px 50px;
    background: #3360FF;
    border-radius: 15px;
    border: none;
    color: white;
}

@media only screen and (max-width:1100px) {
    .header {
        width: 100%;
        padding: 10px 0px;
    }

    .Union {
        display: flex;
        width: 40%;
    }

    .Union img {
        width: 5%;
        height: 18px;

    }

    .Union h6 {
        width: 70%;
        padding: 0px 0px 0px 8px;
        font-size: 17px;
    }

    .ull {
        display: flex;
        width: 20%;
    }

    .ull ul li {
        list-style: none;
        padding: 0px 10px;
        color: #666666;
        font-size: 14px;
    }

    .aa {
        text-decoration: none;
        list-style: none;
        padding: 0px 10px;
        color: #666666;
        font-size: 14px;
    }

    .ull ul {
        display: flex;

        justify-content: space-between;
    }

    .but {
        width: 11%;
        align-items: end;
    }

    .but button {

        padding: 8px 40px;
        background: #3360FF;
        border-radius: 15px;
        border: none;
        color: white;
    }


}

@media only screen and (max-width: 925px) {
    .header {
        width: 100%;
        padding: 10px 0px;
    }

    .Union {
        display: flex;
        width: 40%;
    }

    .Union img {
        width: 6%;
        height: 18px;

    }

    .Union h6 {
        width: 70%;
        padding: 0px 0px 0px 8px;
        font-size: 17px;
    }

    .ull {
        display: flex;
        width: 40%;
    }

    .ull ul li {
        list-style: none;
        padding: 0px 10px;
        color: #666666;
        font-size: 14px;
    }

    .aa {
        text-decoration: none;
        list-style: none;
        padding: 0px 10px;
        color: #666666;
        font-size: 14px;
    }

    .ull ul {
        display: flex;

        justify-content: space-between;
    }

    .but {
        width: 14%;
        align-items: end;
    }

    .but button {

        padding: 8px 40px;
        background: #3360FF;
        border-radius: 15px;
        border: none;
        color: white;
    }


}





@media only screen and (max-width: 600px) {
    .header {
        width: 100%;
        padding: 10px 0px;
    }

    .Union {
        display: flex;
        width: 50%;
    }

    .Union img {
        width: 7%;
        height: 18px;

    }

    .ull {
        display: none;

    }

    .but {
        display: none;
    }

}


@media only screen and (max-width: 330px) {
    .header {
        width: 100%;
        padding: 10px 0px;
    }

    .Union {
        display: flex;
        width: 50%;
    }

    .Union img {
        width: 12%;
        height: 18px;

    }

    .ull {
        display: none;

    }

    .but {
        display: none;
    }
}

/* =======================text_img============================ */

.text_img {
    width: 100%;
    display: flex;
    padding-top: 120px;

}

.text {
    width: 50%;

    padding: 0px 80px 0px 0px;
}

.text h1 {
    color: #3375FF;
    font-size: 110px;
}

.text p {
    width: 100%;
    padding: 20px 260px 0px 0px;
    font-size: 20px;
    color: #666666;
}

.dumaloq1 {
    position: absolute;
    left: 420px;
    bottom: 280px;
}

.dumaloq2 {
    position: absolute;
    left: 430px;
    bottom: 190px;
}

.dizinfeksia {
    position: absolute;
    left: 485px;
    bottom: 125px;
}



.but1 {
    padding: 145px 0px 0px 0px;
    display: flex;
    width: 10%;
}

.but3 button {
    padding: 15px 60px;
    font-size: 16px;
    background: #3360FF;
    border-radius: 20px;
    border: none;
    color: white;
}

.strelka {
    padding: 2px 0px 0px 5px;
}

.imgg {
    width: 50%;
    padding: 0px 0px 0px 34px;
}

@media only screen and (max-width:1100px) {

    .dumaloq1 {
        position: absolute;
        left: 240px;
        bottom: 300px;
        width: 5%;
    }

    .dumaloq2 {
        position: absolute;
        left: 250px;
        bottom: 275px;
        width: 7%;
    }

    .dizinfeksia {
        position: absolute;
        left: 275px;
        bottom: 255px;
        width: 10%;
    }



    .text h1 {

        color: #3375FF;
        font-size: 50px;
    }

    .text p {
        width: 100%;
        padding: 20px 220px 0px 0px;
        font-size: 17px;
        color: #666666;
    }

    .but1 {
        padding: 110px 0px 0px 0px;
        display: flex;
        width: 100%;
    }

    .but3 button {
        padding: 10px 40px;
        font-size: 14px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }

    .strelka {
        width: 100%;
        padding: 2px 0px 0px 5px;
    }

    .strelka img {
        width: 30%;
    }

    .imgg {
        width: 70%;

        padding: 0px 0px 0px 10px;
    }

    .imgg img {
        width: 100%;
    }

}

@media only screen and (max-width:925px) {
    .text_img {
        width: 100%;
        display: flex;
        padding-top: 120px;

    }

    .text {

        width: 50%;

        padding: 0px 80px 0px 0px;
    }

    .text h1 {

        color: #3375FF;
        font-size: 40px;
    }

    .text p {
        width: 100%;
        padding: 20px 20px 0px 0px;
        font-size: 15px;
        color: #666666;
    }

    .dumaloq1 {
        position: absolute;
        left: 250px;
        bottom: 300px;
        width: 5%;
    }

    .dumaloq2 {
        position: absolute;
        left: 250px;
        bottom: 275px;
        width: 7%;
    }

    .dizinfeksia {
        position: absolute;
        left: 265px;
        bottom: 255px;
        width: 10%;
    }



    .but1 {
        padding: 120px 0px 0px 0px;
        display: flex;
        width: 100%;
    }

    .but3 button {
        padding: 10px 40px;
        font-size: 14px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }

    .strelka {
        width: 100%;
        padding: 2px 0px 0px 5px;
    }

    .strelka img {
        width: 50%;
    }


    .imgg {
        width: 70%;

        padding: 0px 0px 0px 10px;
    }

    .imgg img {
        width: 100%;
    }

}

@media only screen and (max-width: 782px) {


    .text {

        width: 50%;

        padding: 0px 80px 0px 0px;
    }

    .text h1 {

        color: #3375FF;
        font-size: 40px;
    }

    .text p {
        width: 100%;
        padding: 20px 20px 0px 0px;
        font-size: 15px;
        color: #666666;
    }

    .dumaloq1 {
        position: absolute;
        left: 220px;
        bottom: 240px;
        width: 4%;
    }

    .dumaloq2 {
        position: absolute;
        left: 230px;
        bottom: 225px;
        width: 5%;
    }

    .dizinfeksia {
        position: absolute;
        left: 245px;
        bottom: 215px;
        width: 8%;
    }



    .but1 {
        padding: 90px 0px 0px 0px;
        display: flex;
        width: 100%;
    }

    .but3 button {
        padding: 10px 40px;
        font-size: 14px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }

    .strelka {
        width: 100%;
        padding: 2px 0px 0px 5px;
    }

    .strelka img {
        width: 80%;
    }


    .imgg {
        width: 70%;

        padding: 0px 0px 0px 10px;
    }

    .imgg img {
        width: 100%;
    }

}


@media only screen and (max-width: 600px) {
    .text_img {
        width: 100%;
        display: flex;
        padding-top: 50px;
        height: 300px;
    }

    .text {
        width: 50%;

        padding: 0px 20px 0px 0px;

    }

    .text h1 {
        color: #3375FF;
        font-size: 28px;
    }

    .text p {
        width: 100%;
        padding: 20px 60px 20px 0px;
        font-size: 12px;
        color: #666666;
    }

    .dumaloq1 {
        position: none;
        left: 210px;
        bottom: 400px;
        width: 5%;
    }

    .dumaloq2 {
        position: absolute;
        left: 220px;
        bottom: 385px;
        width: 7%;
    }

    .dizinfeksia {
        position: absolute;
        left: 230px;
        bottom: 360px;
        width: 12%;
    }

    .strelka {
        width: 100%;
        padding: 5px 0px 0px 10px;


    }

    .strelka img {
        width: 40%;
    }

    .but1 {
        padding: 20px 0px 0px 0px;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .but3 button {
        padding: 10px 30px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }

    .imgg {
        width: 50%;
        padding: 0px 0px 0px 34px;
    }

    .imgg img {
        width: 100%;
    }
}

@media only screen and (max-width:552px) {
    .text_img {
        width: 100%;
        display: flex;
        padding-top: 50px;
        height: 280px;
    }

    .text {
        width: 50%;

        padding: 0px 10px 0px 0px;

    }

    .text h1 {
        color: #3375FF;
        font-size: 25px;
    }

    .text p {
        width: 100%;
        padding: 20px 30px 10px 0px;
        font-size: 12px;
        color: #666666;
    }

    .dumaloq1 {
        position: none;
        left: 150px;
        bottom: 400px;
        width: 5%;
    }

    .dumaloq2 {
        position: absolute;
        left: 155px;
        bottom: 385px;
        width: 7%;
    }

    .dizinfeksia {
        position: absolute;
        left: 160px;
        bottom: 360px;
        width: 12%;
    }

    .strelka {
        width: 100%;
        padding: 5px 0px 0px 10px;


    }

    .strelka img {
        width: 50%;
    }

    .but1 {
        padding: 10px 0px 0px 0px;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .but3 button {
        padding: 10px 30px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }



    .imgg {
        width: 60%;
        padding: 0px 0px 0px 34px;
    }

    .imgg img {
        width: 100%;
    }
}

@media only screen and (max-width: 462px) {
    .text_img {
        width: 100%;
        display: flex;
        padding-top: 50px;
        height: 250px;
    }

    .text {
        width: 50%;

        padding: 0px 20px 0px 0px;

    }

    .text h1 {
        color: #3375FF;
        font-size: 28px;
    }

    .text p {
        width: 100%;
        padding: 10px 30px 0px 0px;
        font-size: 10px;
        color: #666666;
    }

    .dumaloq1 {
        position: none;
        left: 130px;
        bottom: 400px;
        width: 5%;
    }

    .dumaloq2 {
        position: absolute;
        left: 135px;
        bottom: 390px;
        width: 7%;
    }

    .dizinfeksia {
        position: absolute;
        left: 140px;
        bottom: 370px;
        width: 12%;
    }

    .strelka {
        width: 100%;
        padding: 5px 0px 0px 10px;


    }

    .strelka img {
        width: 50%;
    }

    .but1 {
        padding: 20px 0px 0px 0px;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .but3 button {
        padding: 7px 15px;
        font-size: 8px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }



    .imgg {
        width: 100%;
        padding: 0px 0px 0px 34px;
    }

    .imgg img {
        width: 100%;
    }
}


@media only screen and (max-width: 330px) {
    .text_img {
        width: 100%;
        background-image: url(./rasm/rasm1.png);
        background-repeat: round;
        height: 200px;
        padding: 50px 0px;
    }

    .text {
        width: 100%;
        text-align: center;
        padding: 40px 0px 10px 0px;
    }

    .text h1 {
        width: 100%;

        color: #F3F6F6;
        font-size: 20px;
    }

    .text p {
        text-align: center;
        width: 100%;
        padding: 20px 10px 20px 10px;
        font-size: 15px;
        color: #F3F6F6;
    }

}





/* ====================Avfzalliklar==================== */
.Avfzalliklar {
    padding: 100px 30px;

}

hr {
    padding: 0px 10px 0px 0px;
}

.Avfzalliklar h1 {
    font-size: 38px;
    padding: 10px 0px;
    width: 100%;
}

.text2 {
    display: flex;
    justify-content: space-between;
}

.tez {
    padding: 10px;
}

.tez img {
    padding: 10px 0px;
}

.tez h3 {
    font-size: 20px;
    padding: 10px 0px;
}

.tez p {
    width: 98%;
    padding: 0px 120px 0px 0px;
}

@media only screen and (max-width: 725px) {
    .Avfzalliklar {
        padding: 20px 10px;
    }

    .Avfzalliklar h1 {
        font-size: 25px;
        padding: 10px 0px;
        width: 100%;
        text-align: center;
    }

    .tez h3 {
        font-size: 17px;
        padding: 10px 0px;
    }

    .tez p {
        width: 100%;
        padding: 0px 0px 0px 0px;
        font-size: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .Avfzalliklar h1 {
        font-size: 20px;
        padding: 10px 0px;
        width: 100%;
        text-align: center;
    }

    .tez img {
        width: 50%;
        padding: 0px 0px;
    }
}

@media only screen and (max-width: 330px) {
    .Avfzalliklar {
        padding: 50px 10px;
    }

    .Avfzalliklar h1 {
        font-size: 25px;
        padding: 10px 0px;
        width: 100%;
        text-align: center;
    }

    .text2 {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        padding: 10px 0px;
    }

    .tez {
        border-radius: 10px;
        border: 1px solid #676D83;
    }

    .tez h3 {
        font-size: 18px;
        padding: 10px 0px;
    }

    .tez img {
        width: 20%;
        padding: 0px 0px;
    }

    .tez p {
        width: 100%;
        padding: 0px 0px 0px 0px;
        font-size: 15px;
    }

}


/* ====================katta_img ============ */
.katta_img {
    width: 100%;
    background-image: url(./rasm/rasm2.png);
    background-repeat: round;
    height: 500px;
}


.text3 {
    width: 100%;
    padding: 30px 70px 0px 750px;
    color: white;
}

.text3 h3 {
    font-size: 40px;
    padding: 10px 50px 50px 0px;
}

.text3 p {
    font-size: 20px;
    padding: 0px 50px 0px 0px;
}

.but2 {
    padding: 80px 0px 30px 0px;
}

.but2 button {

    padding: 15px 60px;
    font-size: 16px;
    background: #3360FF;
    border-radius: 20px;
    border: none;
    color: white;
}

@media only screen and (max-width:1224px) {

    .katta_img {
        width: 100%;
        background-image: url(./rasm/rasm2.png);
        background-repeat: round;
        height: 500px;
    }

    .text3 {
        width: 100%;
        padding: 10px 70px 0px 550px;
        font-size: 40px;
        color: white;
    }

    .text3 h3 {

        font-size: 40px;
        padding: 20px 10px 10px 10px;
    }

    .text3 p {
        font-size: 28px;

        padding: 20px 20px 10px 10px;
    }

    .but2 {
        padding: 20px 0px 10px 10px;

    }

    .but2 button {

        padding: 15px 35px;
        font-size: 20px;
        background: #3360FF;
        border-radius: 10px;
        border: none;
        color: white;
    }

}

@media only screen and (max-width:1000px) {
    .katta_img {
        width: 100%;
        background-image: url(./rasm/rasm2.png);
        background-repeat: round;
        height: 500px;
    }

    .text3 {
        width: 100%;
        padding: 10px 70px 0px 450px;
        font-size: 40px;
        color: white;
    }

    .text3 h3 {

        font-size: 30px;
        padding: 10px 10px 10px 10px;
    }

    .text3 p {
        font-size: 25px;

        padding: 20px 20px 10px 10px;
    }

    .but2 {
        padding: 10px 0px 10px 10px;

    }

    .but2 button {

        padding: 15px 35px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 10px;
        border: none;
        color: white;
    }
}

@media only screen and (max-width:800px) {
    .katta_img {
        width: 100%;
        background-image: url(./rasm/rasm2.png);
        background-repeat: round;
        height: 300px;
    }

    .text3 {
        width: 100%;
        padding: 10px 70px 0px 70px;
        font-size: 40px;
        color: white;
    }

    .text3 h3 {

        text-align: center;

        font-size: 30px;
        padding: 10px 10px 5px 10px;
    }

    .text3 p {
        font-size: 20px;
        text-align: center;
        padding: 10px 20px 10px 20px;
    }

    .but2 {
        padding: 10px 0px 10px 0px;
        text-align: center;
    }

    .but2 button {

        padding: 8px 15px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 10px;
        border: none;
        color: white;
    }
}

@media only screen and (max-width:600px) {
    .katta_img {
        width: 100%;
        background-image: url(./rasm/rasm2.png);
        background-repeat: round;
        height: 300px;
    }

    .text3 {
        width: 100%;
        padding: 0;
        font-size: 40px;
        color: white;
    }

    .text3 h3 {

        text-align: center;

        font-size: 30px;
        padding: 5px 10px 5px 10px;
    }

    .text3 p {
        font-size: 20px;
        text-align: center;
        padding: 10px 20px 8px 20px;
    }

    .but2 {
        padding: 10px 0px 10px 0px;
        text-align: center;
    }

    .but2 button {

        padding: 8px 15px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 10px;
        border: none;
        color: white;
    }
}


@media only screen and (max-width: 330px) {


    .katta_img {
        width: 100%;
        background-image: url(./rasm/rasm2.png);
        background-repeat: round;
        height: 200px;
        align-items: center;
    }

    .text3 {
        width: 100%;
        padding: 0px 10px 0px 10px;
        color: white;

    }

    .text3 h3 {

        text-align: center;

        font-size: 17px;
        padding: 5px 30px 5px 30px;
    }

    .text3 p {
        font-size: 14px;
        text-align: center;
        padding: 0px 20px 8px 20px;
    }

    .but2 {
        padding: 0px 0px 0px 0px;
        text-align: center;
    }

    .but2 button {

        padding: 8px 15px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 10px;
        border: none;
        color: white;
    }

}




/* ==============service ============= */
.Service {
    padding: 100px 20px;

}

.Service h1 {
    font-size: 48px;
    padding: 10px 0px;
}

.Service p {
    width: 20%;
    padding: 10px 0px 20px 0px;
    color: #666666;

}

.katta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 100px 0px;
}

@media only screen and (max-width:800px) {
    .Service {
        padding: 80px 20px;
        width: 100%;
    }

    .Service h1 {

        font-size: 45px;
        padding: 10px 0px;
    }

    .Service p {

        width: 100%;
        padding: 10px 0px 10px 0px;
        color: #666666;
        font-size: 18px;
    }

}

@media only screen and (max-width:600px) {
    .Service {
        padding: 80px 20px;
        text-align: center;
    }

    .Service h1 {
        font-size: 42px;
        padding: 10px 0px;
    }

    .Service p {

        width: 100%;
        padding: 10px 0px 10px 0px;
        color: #666666;
        font-size: 15px;
    }
}

@media only screen and (max-width:500px) {
    .Service {
        padding: 80px 20px;
        text-align: center;

    }

    .Service h1 {
        text-align: center;
        font-size: 40px;
        padding: 10px 0px;
    }
}


@media only screen and (max-width: 330px) {
    .Service {
        padding: 30px 0px;
        text-align: center;
    }

    .Service h1 {
        font-size: 28px;
        padding: 10px 0px;
    }

    .Service p {
        text-align: center;
        width: 100%;
        padding: 0px 0px 0px 0px;
        color: #666666;

    }

    .katta {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0px 0px 0px 0px;
    }


}

/* ==================bosh ============ */
.bosh {
    background: #F3F6F6;
    padding: 20px 30px;
    width: 33%;
    height: 450px;
}

.h3 {
    font-size: 28px;
    padding: 0px 50px 10px 0px;
    width: 80%;
}

.naz {
    font-size: 28px;
    padding: 10px 0px 10px 0px;
    color: #056AC7;

}

.dars {
    padding: 10px 0px 10px 0px;
    color: #056AC7;
}

.img1 {
    padding: 160px 0px 0px 320px;
}

@media only screen and (max-width:1000px) {
    .katta {
        width: 100%;

        gap: 20px;
        padding: 10px 0px 30px 0px;
    }

    .bosh {
        background: #F3F6F6;
        padding: 20px 30px;
        width: 33%;
        height: 450px;
    }

    .img1 {
        padding: 120px 0px 0px 100px;
        width: 100%;
    }

    .img1 img {
        width: 100%;
    }
}

@media only screen and (max-width:800px) {
    .katta {
        width: 100%;

        gap: 20px;
        padding: 10px 0px 30px 0px;
    }

    .bosh {
        background: #F3F6F6;
        padding: 20px 30px;
        width: 33%;
        height: 390px;
    }

    .img1 {
        padding: 70px 0px 0px 70px;
        width: 100%;
    }

    .img1 img {
        width: 100%;
    }
}

@media only screen and (max-width:600px) {
    .katta {

        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 0px 0px 100px 0px;
    }

    .bosh {

        background: #F3F6F6;
        padding: 20px 20px;
        width: 33%;
        height: 300px;
    }

    .h3 {
        font-size: 13px;
        padding: 0px 20px 10px 0px;
        width: 80%;
    }

    .naz {
        font-size: 20px;
        padding: 10px 0px 10px 0px;
        color: #056AC7;

    }

    .dars {
        padding: 10px 0px 10px 0px;
        color: #056AC7;
    }

    .img1 {
        padding: 30px 0px 0px 0px;
        width: 70%;
    }

    .img1 img {
        width: 100%;
    }

}


@media only screen and (max-width: 330px) {

    /* ==================bosh ============ */
    .bosh {
        background: #F3F6F6;
        padding: 10px 30px;
        width: 100%;
        height: 200px;
    }

    .h3 {
        text-align: center;
        font-size: 18px;
        padding: 0px 0px 0px 0px;
        width: 100%;
    }

    .naz {
        text-align: center;
        font-size: 22px;
        padding: 10px 0px 10px 0px;
        color: #056AC7;

    }

    .dars {
        text-align: center;
        font-size: 15px;
        padding: 10px 0px 5px 0px;
        color: #056AC7;
    }

    .img1 {
        padding: 10px 0px 0px 60px;
        align-items: center;
    }

    .img1 img {

        width: 40%;
    }

}





/* ================orta ============= */
.orta {
    background: #F3F6F6;
    padding: 20px 30px;
    width: 33%;
    height: 450px;
}

.h3_1 {
    font-size: 28px;
    padding: 0px 50px 10px 0px;
    width: 80%;
}

.nazariy {

    font-size: 25px;
    padding: 40px 0px 0px 0px;
    color: #056AC7;

}

.dars1 {
    padding: 20px 0px 10px 0px;
    color: #00A9B3;
}

.img2 {
    padding: 163px 0px 0px 320px;
}


@media only screen and (max-width:1000px) {
    .katta {
        width: 100%;

        gap: 20px;
        padding: 10px 0px 30px 0px;
    }

    .orta {
        background: #F3F6F6;
        padding: 20px 30px;
        width: 33%;
        height: 450px;
    }

    .img2 {
        padding: 120px 0px 0px 80px;
        width: 100%;
    }

    .img2 img {
        width: 100%;
    }
}



@media only screen and (max-width:800px) {
    .katta {
        width: 100%;

        gap: 20px;
        padding: 10px 0px 30px 0px;
    }

    .orta {
        background: #F3F6F6;
        padding: 20px 30px;
        width: 33%;
        height: 390px;
    }

    .img2 {
        padding: 50px 0px 0px 40px;
        width: 100%;
    }

    .img2 img {
        width: 100%;
    }
}


@media only screen and (max-width:600px) {
    .katta {

        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 0px 0px 50px 0px;
    }

    .orta {

        background: #F3F6F6;
        padding: 20px 20px;
        width: 33%;
        height: 300px;
    }

    .h3_1 {
        font-size: 13px;
        padding: 0px 20px 10px 0px;
        width: 80%;
    }

    .nazariy {
        font-size: 20px;
        padding: 10px 0px 10px 0px;
        color: #056AC7;

    }

    .dars1 {
        padding: 10px 0px 10px 0px;
        color: #056AC7;
    }

    .img2 {
        padding: 30px 0px 0px 0px;
        width: 70%;
    }

    .img2 img {
        width: 100%;
    }

}

@media only screen and (max-width:500px) {
    .katta {

        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 0px 0px 50px 0px;
    }

    .orta {

        background: #F3F6F6;
        padding: 20px 20px;
        width: 33%;
        height: 300px;
    }

    .h3_1 {
        font-size: 13px;
        padding: 0px 20px 10px 0px;
        width: 80%;
    }

    .nazariy {
        font-size: 20px;
        padding: 10px 0px 10px 0px;
        color: #056AC7;

    }

    .dars1 {
        padding: 10px 0px 10px 0px;
        color: #056AC7;
    }

    .img2 {
        padding: 30px 0px 0px 20px;
        width: 100%;
    }

    .img2 img {
        width: 90%;
    }

}




@media only screen and (max-width: 330px) {
    .orta {
        padding: 10px 30px;
        width: 100%;
        height: 200px;
    }

    .h3_1 {
        text-align: center;
        font-size: 18px;
        padding: 0px 0px 0px 0px;
        width: 100%;
    }

    .nazariy {
        text-align: center;
        font-size: 22px;
        padding: 10px 0px 10px 0px;
        color: #056AC7;

    }

    .dars1 {
        text-align: center;
        font-size: 15px;
        padding: 10px 0px 5px 0px;
        color: #056AC7;
    }

    .img2 {
        padding: 10px 0px 0px 60px;
    }

    .img2 img {
        width: 40%;
    }

}

/* ===========oxiri ============== */

.yuqori {
    background: #F3F6F6;
    padding: 20px 30px;
    width: 33%;
    height: 450px;
}

.h3_2 {
    font-size: 28px;
    padding: 0px 50px 10px 0px;
    width: 80%;
}

.nazar {
    font-size: 28px;
    color: #009AA3;
    padding: 40px 0px 0px 0px;
}



.dars2 {
    padding: 20px 0px 10px 0px;
    color: #00A9B3;
}

.img3 {
    padding: 163px 0px 0px 320px;
}

@media only screen and (max-width:1000px) {
    .katta {
        width: 100%;

        gap: 20px;
        padding: 10px 0px 30px 0px;
    }

    .yuqori {
        background: #F3F6F6;
        padding: 20px 30px;
        width: 33%;
        height: 450px;
    }

    .img3 {
        padding: 120px 0px 0px 80px;
        width: 100%;
    }

    .img3 img {
        width: 100%;
    }

}

@media only screen and (max-width:800px) {
    .katta {
        width: 100%;

        gap: 20px;
        padding: 10px 0px 30px 0px;
    }

    .yuqori {
        background: #F3F6F6;
        padding: 20px 30px;
        width: 33%;
        height: 390px;
    }

    .img3 {
        padding: 70px 0px 0px 50px;
        width: 100%;
    }

    .img3 img {
        width: 100%;
    }
}

@media only screen and (max-width:600px) {
    .katta {

        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 0px 0px 100px 0px;
    }

    .yuqori {

        background: #F3F6F6;
        padding: 20px 20px;
        width: 33%;
        height: 300px;
    }

    .h3_2 {
        font-size: 13px;
        padding: 0px 20px 10px 0px;
        width: 80%;
    }

    .nazar {
        font-size: 20px;
        padding: 15px 0px 10px 0px;
        color: #056AC7;

    }

    .dars2 {
        padding: 18px 0px 10px 0px;
        color: #056AC7;
    }

    .img3 {
        padding: 30px 0px 0px 0px;
        width: 70%;
    }

    .img3 img {
        width: 100%;
    }
}

@media only screen and (max-width: 330px) {
    .yuqori {
        background: #F3F6F6;
        padding: 10px 30px;
        width: 100%;
        height: 200px;
    }

    .h3_2 {
        text-align: center;
        font-size: 18px;
        padding: 0px 0px 0px 0px;
        width: 100%;
    }

    .nazar {
        text-align: center;
        font-size: 22px;
        padding: 10px 0px 10px 0px;
        color: #056AC7;
    }



    .dars2 {
        text-align: center;
        font-size: 15px;
        padding: 10px 0px 5px 0px;
        color: #056AC7;
    }

    .img3 {
        padding: 10px 0px 0px 60px;
    }

    .img3 img {
        width: 40%;
    }
}

/* ===========fon rasm ========= */

.katta_fon {

    width: 100%;
    background-image: url(./rasm/fon.png);
    background-repeat: round;
    height: 550px;

    display: flex;

}

.rasm3 {
    width: 50%;
    padding: 20px 20px 0px 20px;
}

.text4 {
    width: 50%;
}

.togri {
    padding: 20px 0px 10px 0px;
}

.text5 {
    color: white;
}

.text5_h2 {
    font-size: 20px;
    padding: 30px 300px 100px 0px;
}


.btn3 {
    padding: 50px 0px 30px 0px;
}

.btn3 button {

    padding: 15px 60px;
    font-size: 16px;
    background: #3360FF;
    border-radius: 20px;
    border: none;
    color: white;
}



@media only screen and (max-width:1000px) {
    .katta_fon {
        height: 465px;
        width: 100%;
    }

    .rasm3 {
        width: 50%;
        padding: 20px 20px 0px 20px;
    }

    .rasm3 img {
        width: 100%;
    }

    .text4 {

        width: 50%;
        padding: 0px 0px 0px 50px;
    }

    .text5 {
        font-size: 18px;
    }

    .text5_h2 {
        font-size: 15px;
        padding: 30px 20px 100px 0px;
    }


    .btn3 {
        padding: 20px 0px 30px 0px;
        font-size: 16px;
    }

    .btn3 button {
        padding: 15px 30px;
    }


    .togri {
        padding: 10px 0px 10px 0px;
        width: 20%;
    }

    .togri img {
        width: 100%;
    }
}

@media only screen and (max-width:800px) {
    .katta_fon {
        height: 380px;
    }
}

@media only screen and (max-width:600px) {
    .katta_fon {
        width: 100%;
        height: 250px;
        padding: 0px 0px 0px 0px;
    }

    .rasm3 {

        width: 50%;
        padding: 10px 10px 10px 10px;

    }

    .rasm3 img {
        width: 100%;
        height: 200px;
    }

    .text4 {
        width: 50%;

    }

    .togri {

        padding: 10px 0px 10px 0px;
    }

    .text5 {

        padding: 10px 0px;
        color: white;
    }

    .text5_h2 {
        font-size: 10px;
        padding: 0px 0px 0px 0px;
    }

    .btn3 button {

        padding: 10px 20px;
        font-size: 12px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }



}


@media only screen and (max-width: 330px) {
    .katta_fon {

        width: 100%;
        background-image: none;

        display: flex;
        flex-direction: column;
        height: 480px;

    }

    .rasm3 {

        width: 100%;
        padding: 10px 0px 0px 0px;

    }

    .rasm3 img {
        width: 100%;
        height: 200px;
    }

    .text4 {
        width: 100%;

    }

    .togri {
        text-align: center;
        padding: 10px 0px 10px 0px;
    }

    .text5 {
        text-align: center;
        padding: 10px 0px;
        color: #666666;
    }

    .text5_h2 {
        font-size: 10px;
        padding: 0px 0px 0px 0px;
    }


    .btn3 {
        text-align: center;
        padding: 10px 0px 0px 0px;
    }

    .btn3 button {

        padding: 10px 30px;
        font-size: 15px;
        background: #3360FF;
        border-radius: 20px;
        border: none;
        color: white;
    }
}

/* ============ faq ============== */

/*============ malumot ==========*/

.malumot {
    width: 100%;
    background-image: url(./rasm/fon.png);
    background-repeat: round;
    height: 457px;

}

.malum {
    width: 100%;
    padding: 20px;
    display: flex;
    gap: 30px;
}

.malum1 {
    width: 40%;
    height: 415px;
    padding: 30px;
    background-color: #F3F6F6;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.qol {
    font-size: 45px;
    padding: 0px 200px 0px 0px;
    color: var(--Text-1, #242825);

}


.ism {
    padding: 20px;
    border: none;
    font-size: 16px;

}

.tel {
    padding: 20px;
    border: none;
    font-size: 16px;
}

.yozilish1 {
    width: 100%;
    padding: 0px 0px 0px 0px;
}

.yozilish {
    width: 100%;
    padding: 15px 0px 15px 0px;
    background-color: var(--Text-1, #242825);
    color: #FFFFFF;
    font-size: 25px;
    border: none;
    border-radius: 10px;
}

.bizga {
    width: 40%;
    padding: 40px 0px 0px 150px;
    font-size: 25px;
    color: #FFFFFF;

}

.rasm5 {
    position: absolute;
    left: 980px;
}

@media only screen and (max-width:1200px) {
    .qol {
        font-size: 29px;
    }

    .rasm5 {
        position: absolute;
        left: 800px;
        width: 0%;
        top: 0px;
    }

}

@media only screen and (max-width:1050px) {

    .malumot {
        height: 450px;
    }

    .yozilish {
        font-size: 20px;
    }

    .rasm5 {
        position: absolute;
        left: 650px;
        width: 0%;
        top: 12px;
    }



    .bizga {
        width: 40%;
        padding: 40px 0px 0px 100px;
        font-size: 20px;
        color: #FFFFFF;

    }
}

@media only screen and (max-width:850px) {
    .rasm5 {
        position: absolute;
        left: 400px;
        width: 40%;
        top: 145px;

    }

    .rasm5 img {
        width: 100%;
    }
}

@media only screen and (max-width:600px) {
    .rasm5 {
        position: absolute;
        left: 200px;
        width: 40%;
        top: 255px;

    }

    .rasm5 img {
        background-image: none;
    }

    .malumot {
        width: 100%;
        background-image: none;
        background-repeat: round;
        height: 100px;
        display: flex;
        flex-direction: column;
        padding: 10px 0px 400px 0px;

    }

    .malum {
        width: 100%;
        padding: 10px 0px 10px 0px;
        display: flex;
        flex-direction: column;

        gap: 20px;
        height: 0px;


    }

    .malum1 {
        width: 100%;
        height: 250px;
        padding: 0px 0px 0px 0px;
        border: 2px solid #666666;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .qol {
        font-size: 15px;
        padding: 10px 0px 0px 0px;
        color: var(--Text-1, #242825);
        text-align: center;
    }


    .ism {

        padding: 10px;
        border: none;
        font-size: 12px;
        width: 80%;
    }

    .tel {
        width: 80%;
        padding: 10px;
        border: none;
        font-size: 12px;
    }

    .yozilish1 {
        width: 100%;
        padding: 10px 10px 10px 10px;
    }

    .yozilish {
        width: 100%;
        padding: 10px 0px 10px 0px;
        background-color: var(--Text-1, #242825);
        color: #FFFFFF;
        font-size: 14px;
        border: none;
        border-radius: 10px;
    }

    .bizga {
        width: 100%;
        padding: 10px 10px 10px 10px;
        font-size: 20px;
        color: #1b0606;

    }
}


@media only screen and (max-width: 330px) {
    .malumot {
        width: 100%;
        background-image: none;
        background-repeat: round;
        height: 100px;
        display: flex;
        flex-direction: column;
        padding: 10px 0px 0px 0px;

    }

    .malum {
        width: 100%;
        padding: 10px 0px 10px 0px;
        display: flex;
        flex-direction: column;

        gap: 20px;
        height: 0px;


    }

    .malum1 {
        width: 100%;
        height: 250px;
        padding: 0px 0px 0px 0px;
        border: 2px solid #666666;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .qol {
        font-size: 15px;
        padding: 10px 0px 0px 0px;
        color: var(--Text-1, #242825);
        text-align: center;
    }


    .ism {

        padding: 10px;
        border: none;
        font-size: 12px;
        width: 80%;
    }

    .tel {
        width: 80%;
        padding: 10px;
        border: none;
        font-size: 12px;
    }

    .yozilish1 {
        width: 100%;
        padding: 10px 10px 10px 10px;
    }

    .yozilish {
        width: 100%;
        padding: 10px 0px 10px 0px;
        background-color: var(--Text-1, #242825);
        color: #FFFFFF;
        font-size: 14px;
        border: none;
        border-radius: 10px;
    }

    .bizga {
        width: 100%;
        padding: 10px 10px 10px 10px;
        font-size: 20px;
        color: #1b0606;

    }

    .rasm5 {
        background-image: none;
    }

}

/* ========footer======= */
.footer {
    display: flex;
    width: 100%;
    padding: 50px 0px;
}

.diz {
    width: 30%;
    padding: 14px 30px;
}

.Union1 {
    display: flex;
    width: 100%;
    align-items: center;
}

.Union1 img {
    width: 5%;
    height: 18px;

}

.Union1 h6 {
    width: 70%;
    padding: 0px 0px 0px 8px;
    font-size: 25px;
}

.joylashuv {
    width: 70%;
}

.tosh {
    display: flex;
    align-items: center;
    gap: 20px;

    padding: 10px 0px 20px 0px;
}

.tarmoqlar {
    display: flex;
    gap: 50px;
    padding: 40px 0px 20px 0px;
}

.fa {
    display: flex;
    padding: 10px 80px;
    border: none;
    border-radius: 10px;
    color: var(--Text-1, #242825);
    font-size: 18px;
}

.qora {
    padding: 0px 0px 0px 20px;
}

@media only screen and (max-width:1200px) {
    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 100px 0px;
    }

    .tarmoqlar {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 40px 0px 20px 0px;
    }
}

@media only screen and (max-width: 330px) {
    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 250px 0px;
    }

    .diz {
        text-align: center;
        width: 100%;

    }

    .Union1 {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0px 0px
    }




    .Union1 img {
        width: 8%;
        height: 18px;

    }

    .Union1 h6 {
        width: 70%;
        padding: 0px 0px 0px 8px;
        font-size: 20px;
    }

    .joylashuv {
        width: 100%;
    }

    .tosh {
        display: flex;
        align-items: center;
        gap: 20px;

        padding: 10px 0px 10px 0px;
    }

    .tarmoqlar {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 40px 0px 20px 0px;
    }

    .fa {
        display: flex;
        padding: 10px 80px;
        border: none;
        border-radius: 10px;
        color: var(--Text-1, #242825);
        font-size: 18px;
    }

    .qora {
        padding: 0px 0px 0px 20px;
    }


}